/*
  Flaticon icon font: Flaticon
  Creation date: 07/11/2017 04:09
  */

@font-face {
    font-family: "Flaticon";
    src: url("../fonts/Flaticon.eot");
    src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../fonts/Flaticon.woff") format("woff"),
    url("../fonts/Flaticon.ttf") format("truetype"),
    url("../fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("./Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 20px;
    font-style: normal;
    margin-left: 20px;
}

.flaticon-female-worker:before { content: "\f100"; }
.flaticon-female-university-graduate-with-cap-on-head-and-eyeglasses:before { content: "\f101"; }
.flaticon-female-user-management:before { content: "\f102"; }
.flaticon-business-woman:before { content: "\f103"; }
.flaticon-business-man:before { content: "\f104"; }
.flaticon-male-user:before { content: "\f105"; }
.flaticon-football-medal:before { content: "\f106"; }
.flaticon-trophy-shape:before { content: "\f107"; }
.flaticon-trophy-hand-drawn-sportive-cup:before { content: "\f108"; }
.flaticon-classroom:before { content: "\f109"; }
.flaticon-students-cap:before { content: "\f10a"; }
.flaticon-study:before { content: "\f10b"; }
.flaticon-college-graduation:before { content: "\f10c"; }
.flaticon-mortarboard:before { content: "\f10d"; }
.flaticon-drawing:before { content: "\f10e"; }
.flaticon-pencil-and-ruler-cross-of-school-materials:before { content: "\f10f"; }
.flaticon-alphabet:before { content: "\f110"; }